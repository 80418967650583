import {
  makeRequest,
  pageLimit,
  PaginatedResponse,
  SuccessResponse,
} from "./client";
import { Role } from "./roles-client";

export async function getMe(): Promise<UserWithRoles | null> {
  return makeRequest<UserWithRoles>("GET", "/api/users/me");
}

export async function getUsers(
  offset: number,
  limit: number = pageLimit
): Promise<PaginatedResponse<User> | null> {
  return makeRequest("GET", "/api/users", {
    limit: limit,
    offset: offset * limit,
  });
}

export async function getUser(id: string): Promise<User | null> {
  return makeRequest("GET", `/api/users/${id}`);
}

export async function deleteUser(id: string): Promise<SuccessResponse | null> {
  return makeRequest("DELETE", `/api/users/${id}`);
}

export async function createUser(
  user: CreateUserRequest
): Promise<User | null> {
  return makeRequest<User>("POST", "/api/users", null, user);
}

export async function updateUser(user: User): Promise<User | null> {
  return makeRequest<User>("PUT", `/api/users/${user.userId}`, null, user);
}

export async function resetUserPassword(id: string): Promise<User | null> {
  return makeRequest("POST", `/api/users/${id}/reset_password`);
}

export interface UserPermissions {
  roleCount: number;
  canReadConnections: boolean;
  canReadDataTables: boolean;
  canReadUsers: boolean;
  canReadRoles: boolean;
  canUpload: boolean;
}

export interface User {
  userId: string | null;
  email: string;
  firstName: string;
  lastName: string;
  permissions: UserPermissions | null;
  owner: boolean;
  createdAtUtc: Date;
  updatedAtUtc: Date;
}

export interface CreateUserRequest extends User {
  roles: { roleId: string }[];
}

export interface UserWithRoles extends User {
  roles: Role[];
}

export enum RoleEntityType {
  Connection = "connection",
  DataTable = "dataTable",
  DataTableUpload = "dataTableUpload",
  User = "user",
}
