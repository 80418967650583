import { Flex, Spinner } from "@chakra-ui/react";
import styles from "./LoadingPage.module.scss";

function LoadingPage() {
  return (
    <Flex className={styles.container}>
      <Spinner
        colorScheme="primaryScheme"
        size="xl"
        label="Loading..."
        speed="0.6s"
        thickness="4px"
      />
    </Flex>
  );
}

export default LoadingPage;
