import { create } from "zustand";
import {
  DataTableUpload,
  DataTableUploadStatus,
  getUploadById,
  importUpload,
  validateUpload,
} from "../api/data-table-uploads-client";
import { waitUntil } from "../util/condition";

export interface UploadService {
  processingUpload: DataTableUpload | null;
  setProcessingUpload: (id: string | null) => Promise<void>;
  requestImportAndWait: (id: string) => Promise<boolean>;
  requestValidateAndWait: (id: string) => Promise<boolean>;
  refreshProcessingUpload: () => Promise<DataTableUpload | null>;
  currentPollId: NodeJS.Timeout | null;
}

export const useUploadService = create<UploadService>((set, get) => ({
  processingUpload: null,
  currentPollId: null,
  setProcessingUpload: async (id: string | null) => {
    if (!id) {
      set({ processingUpload: null });
      return;
    }

    const upload = await getUploadById(id);
    set({ processingUpload: upload });
  },
  requestValidateAndWait: async (id: string): Promise<boolean> => {
    console.log("requestValidate", id);
    await get().setProcessingUpload(id);
    await validateUpload(id);

    const currentPollId = get().currentPollId;
    if (currentPollId) {
      clearTimeout(currentPollId);
      set({ currentPollId: null });
    }

    await waitUntil(
      async () => {
        await get().refreshProcessingUpload();
        const upload = get().processingUpload;

        return (
          (upload?.status &&
            [
              DataTableUploadStatus.PENDING_EXPORT,
              DataTableUploadStatus.EXPORTING,
              DataTableUploadStatus.FAILED,
              DataTableUploadStatus.CANCELLED,
              DataTableUploadStatus.PROCESSED,
            ].indexOf(upload?.status) !== -1) ||
          false
        );
      },
      1000,
      (timeoutId) => set({ currentPollId: timeoutId })
    );

    const upload = get().processingUpload;
    return (
      (upload?.status &&
        [DataTableUploadStatus.FAILED, DataTableUploadStatus.CANCELLED].indexOf(
          upload?.status
        ) === -1) ||
      false
    );
  },
  requestImportAndWait: async (id: string): Promise<boolean> => {
    console.log("requestImport", id);
    await get().setProcessingUpload(id);
    await importUpload(id);

    const currentPollId = get().currentPollId;
    if (currentPollId) {
      clearTimeout(currentPollId);
      set({ currentPollId: null });
    }

    await waitUntil(
      async () => {
        await get().refreshProcessingUpload();
        const upload = get().processingUpload;

        return (
          (upload?.status &&
            [
              DataTableUploadStatus.IMPORTED,
              DataTableUploadStatus.FAILED,
              DataTableUploadStatus.CANCELLED,
              DataTableUploadStatus.EXPORTING,
              DataTableUploadStatus.PENDING_EXPORT,
              DataTableUploadStatus.PROCESSED,
            ].indexOf(upload?.status) !== -1) ||
          false
        );
      },
      1000,
      (timeoutId) => set({ currentPollId: timeoutId })
    );

    const upload = get().processingUpload;
    return (
      (upload?.status &&
        [DataTableUploadStatus.FAILED, DataTableUploadStatus.CANCELLED].indexOf(
          upload?.status
        ) === -1) ||
      false
    );
  },
  refreshProcessingUpload: async (): Promise<DataTableUpload | null> => {
    const id = get().processingUpload?.dataTableUploadId;
    if (!id) return null;

    try {
      const upload = await getUploadById(id);

      set({ processingUpload: upload });

      return upload;
    } catch (e) {
      console.log("error refreshing upload", e);

      return null;
    }
  },
}));
