import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { FaPlus } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Connection, getConnections } from "../api/connections-client";
import { DataTable, getDataTables } from "../api/data-tables-client";
import { getUsers, User } from "../api/users-client";
import SimpleTable, {
  TableColumn,
  TableColumnDataType,
} from "../components/SimpleTable";
import { isNullUUID } from "../util/uuid";

import styles from "./MutateRole.module.scss";
import { DeleteSimpleDialog } from "../components/SimpleDialog";
import BreadcrumbBar from "../components/BreadcrumbBar";
import {
  createRole,
  deleteRole,
  getRole,
  updateRole,
} from "../api/roles-client";
import {
  hasDelete,
  hasRead,
  hasUpload,
  hasWrite,
  Permission,
} from "../util/permission";

function addPermissions(current: string, toAddList: string[]): string {
  const perms = current.split("+");
  const combined = new Set(perms.concat(toAddList));
  return Array.from(combined)
    .filter((p) => p.length)
    .join("+");
}

function removePermission(current: string, toRemove: string): string {
  const perms = current.split("+");
  if (perms.indexOf(toRemove) === -1) return current;

  return perms.filter((p) => p !== toRemove).join("+");
}

function permissionCheckBoxes(
  id: string,
  permissions: string,
  readLabel: string | null,
  writeLabel: string | null,
  deleteLabel: string | null,
  onChange: (value: string) => void
) {
  return (
    <Flex className={styles.permissionCheckBoxes} gap={1} direction="column">
      {readLabel && (
        <Checkbox
          size="md"
          spacing="1rem"
          disabled={hasWrite(permissions) || hasDelete(permissions)}
          id={`${id}-read`}
          className={styles.checkboxInput}
          onChange={(e) => {
            if (e.target.checked) {
              onChange(addPermissions(permissions, [Permission.Read]));
            } else {
              onChange(removePermission(permissions, Permission.Read));
            }
          }}
          isChecked={hasRead(permissions)}
        >
          {readLabel}
        </Checkbox>
      )}
      {writeLabel && (
        <Checkbox
          size="md"
          spacing="1rem"
          id={`${id}-write`}
          className={styles.checkboxInput}
          onChange={(e) => {
            if (e.target.checked) {
              onChange(
                addPermissions(permissions, [
                  Permission.Write,
                  Permission.Read,
                  Permission.Upload,
                ])
              );
            } else {
              onChange(removePermission(permissions, Permission.Write));
            }
          }}
          isChecked={hasWrite(permissions)}
        >
          {writeLabel}
        </Checkbox>
      )}
      {deleteLabel && (
        <Checkbox
          size="md"
          spacing="1rem"
          id={`${id}-delete`}
          className={styles.checkboxInput}
          onChange={(e) => {
            if (e.target.checked) {
              onChange(
                addPermissions(permissions, [
                  Permission.Delete,
                  Permission.Read,
                ])
              );
            } else {
              onChange(removePermission(permissions, Permission.Delete));
            }
          }}
          isChecked={hasDelete(permissions)}
        >
          {deleteLabel}
        </Checkbox>
      )}
    </Flex>
  );
}

function entitySelectModal(
  isOpen: boolean,
  entityList: any[],
  onClose: () => void,
  setEntityList: (list: any[]) => void
) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Entity Select</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleTable
            className={styles.table}
            data={entityList}
            columns={[
              {
                id: "selected",
                name: "Select",
                dataType: TableColumnDataType.Boolean,
              },
              {
                id: "name",
                name: "Name",
                dataType: TableColumnDataType.Text,
              },
              {
                id: "description",
                name: "Description",
                dataType: TableColumnDataType.Text,
              },
            ]}
            editable={true}
            readOnlyColumns={["name", "description"]}
            onChange={(
              _row: any,
              idx: number,
              column: TableColumn,
              value: any
            ) => {
              const row = entityList[idx];
              let newRow = {
                ...row,
                [column.id]: value,
              };

              setEntityList(
                entityList.map((currRow, rowIdx) =>
                  rowIdx === idx ? newRow : currRow
                )
              );
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function RenderPermissionBlock({
  title,
  type,
  idName,
  isAllBool,
  allPermStr,
  entityList = [],
  entityPermList = [],
  setAllPermStr,
  setIsAllBool = () => {},
  setEntityPermList = () => {},
  disableSpecific = false,
  permStrList,
}: {
  title: string;
  type: string;
  idName: string;
  isAllBool: boolean;
  allPermStr: string;
  entityList?: any[];
  entityPermList?: any[];
  setAllPermStr: (str: string) => void;
  setIsAllBool?: (bool: boolean) => void;
  setEntityPermList?: (list: any[]) => void;
  disableSpecific?: boolean;
  permStrList: string[];
}) {
  const id = [...type.toLowerCase().split(" "), "all"].join("-");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectableEntityList, setSelectableEntityList] = useState<any[]>([]);

  let entityPermColList: TableColumn[] = [
    {
      id: "entityName",
      name: "Name",
      dataType: TableColumnDataType.Text,
    },
    ...(type === "Connections"
      ? [
          {
            id: "uploadTo",
            name: "Upload To",
            dataType: TableColumnDataType.Boolean,
          },
        ]
      : []),
    {
      id: "write",
      name: "Edit",
      dataType: TableColumnDataType.Boolean,
    },
    ...(type === "Data Sources"
      ? [
          {
            id: "upload",
            name: "Upload",
            dataType: TableColumnDataType.Boolean,
          },
        ]
      : []),
    {
      id: "delete",
      name: "Delete",
      dataType: TableColumnDataType.Boolean,
    },
  ];

  // @ts-ignore
  const colWidthList: number[] = Array.from(entityPermColList).fill(80);
  // @ts-ignore
  colWidthList[0] = null;

  function onModalClose() {
    const newEntityPermList = selectableEntityList.reduce((list, curr) => {
      const exists = entityPermList.find(
        (entity) => entity.entityId === curr[idName]
      );
      if (curr.selected) {
        if (exists) {
          list.push(exists);
        } else {
          let newEntity: any = {
            entityName: curr.name,
            entityId: curr[idName],
            uploadTo: true,
            write: false,
            delete: false,
          };
          if (type === "Data Sources") {
            newEntity = {
              entityName: curr.name,
              entityId: curr[idName],
              write: false,
              upload: false,
              delete: false,
            };
          }
          list.push(newEntity);
        }
      }
      return list;
    }, []);
    setEntityPermList(newEntityPermList);
    setIsModalOpen(false);
  }
  return (
    <Box mb={4}>
      <FormLabel className={styles.label} htmlFor="description">
        {title}
      </FormLabel>

      <Tabs
        onChange={(index) => setIsAllBool(!index)}
        variant="unstyled"
        defaultIndex={isAllBool ? 0 : 1}
      >
        {!disableSpecific && (
          <>
            <TabList className={styles.tabList}>
              <Tab>All {type}</Tab>
              <Tab>Specific {type}</Tab>
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="var(--chakra-colors-action)"
              borderRadius="1px"
            />
          </>
        )}

        <TabPanels>
          <TabPanel pl={0} pt={disableSpecific ? 0 : 4}>
            {permissionCheckBoxes(
              id,
              allPermStr,
              permStrList[0],
              permStrList[1],
              permStrList[2],
              setAllPermStr
            )}
          </TabPanel>
          <TabPanel pl={0}>
            {
              <>
                <SimpleTable
                  className={styles.table}
                  data={entityPermList}
                  columns={entityPermColList}
                  colWidths={colWidthList}
                  editable={true}
                  readOnlyColumns={["entityName", "uploadTo"]}
                  onChange={(
                    _row: any,
                    idx: number,
                    column: TableColumn,
                    value: any
                  ) => {
                    const row = entityPermList[idx];
                    let newRow = {
                      ...row,
                      [column.id]: value,
                    };

                    setEntityPermList(
                      entityPermList.map((currRow, rowIdx) =>
                        rowIdx === idx ? newRow : currRow
                      )
                    );
                  }}
                  addBtn={
                    <Flex
                      alignItems="center"
                      gap={2}
                      onClick={() => {
                        unstable_batchedUpdates(() => {
                          setSelectableEntityList(
                            entityList.map((entity) => {
                              return {
                                ...entity,
                                selected: !!entityPermList.find(
                                  (permEntity) =>
                                    permEntity.entityId === entity.connectionId
                                ),
                              };
                            })
                          );
                          setIsModalOpen(true);
                        });
                      }}
                      className={styles.addColumnBtn}
                    >
                      <Icon className={styles.addIcon} as={FaPlus} />
                      Add {type}
                    </Flex>
                  }
                />
                {entitySelectModal(
                  isModalOpen,
                  selectableEntityList,
                  onModalClose,
                  setSelectableEntityList
                )}
              </>
            }
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

interface permissionEntity {
  entityName: string;
  entityId: string | null;
  write: boolean;
  delete: boolean;
}

type Selectable<T> = T & {
  selected: boolean;
};

function formatEntity(
  isAllBool: boolean,
  allPermStr: string,
  entityList: any[]
) {
  if (isAllBool) {
    return allPermStr.length
      ? [
          {
            entityId: null,
            permissions: allPermStr,
          },
        ]
      : [];
  } else {
    return entityList.map((entity) => {
      // if exists in entity list, automatically gets read permission
      let permissionsToAdd: Permission[] = [];
      if (entity.write) permissionsToAdd.push(Permission.Write);
      if (entity.upload) permissionsToAdd.push(Permission.Upload);
      if (entity.delete) permissionsToAdd.push(Permission.Delete);
      return {
        entityId: entity.entityId,
        permissions: addPermissions("r", permissionsToAdd),
      };
    });
  }
}

interface NewRole {
  roleId: string | null;
  name: string;
  connectionPermissions: any[];
  dataTablePermissions: any[];
  dataTableUploadPermissions: any[];
  userPermissions: any[];
  userRoles: any[];
}

// If there are other conditions that would mean role is invalid, change this to return specific error codes to display appropriate error
function isValidRole(role: NewRole) {
  const hasDataTableWrite = role.dataTablePermissions.find((dt) =>
    hasWrite(dt.permissions)
  );
  const hasConnectionRead = role.connectionPermissions.find((c) =>
    hasRead(c.permissions)
  );

  if (hasDataTableWrite && !hasConnectionRead) return false;

  return true;
}

function MutateRole() {
  const { roleId } = useParams();
  const isCreate = roleId == null;

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(roleId != null && roleId !== "");

  const [errMsg, setErrMsg] = useState("");

  const [isAllConnectionPermission, setIsAllConnectionPermissions] =
    useState(true);
  const [isAllDataTablePermission, setIsAllDataTablePermissions] =
    useState(true);
  const [isAllUserPermission, setIsAllUserPermissions] = useState(true);

  const [allConnectionPermissions, setAllConnectionPermissions] = useState("");
  const [allDataTablePermissions, setAllDataTablePermissions] = useState("");
  const [allUploadPermissions, setAllUploadPermissions] = useState("");
  const [allUserPermissions, setAllUserPermissions] = useState("");

  const [connectionEntityList, setConnectionEntityList] = useState<
    permissionEntity[]
  >([]);
  const [dataTableEntityList, setDataTableEntityList] = useState<
    permissionEntity[]
  >([]);

  const [connectionList, setConnectionList] = useState<
    Selectable<Connection>[]
  >([]);
  const [dataTableList, setDataTableList] = useState<Selectable<DataTable>[]>(
    []
  );
  const [userList, setUserList] = useState<Selectable<User>[]>([]);

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  useEffect(
    () => {
      const asyncData = async () => {
        let promiseList: any[] = await Promise.all([
          getConnections(0, 1000),
          getDataTables(0, 1000),
          getUsers(0, 1000),
        ]);
        promiseList = promiseList.map((data, index) => {
          if (index === 2) {
            return (
              data?.results?.map((user) => {
                return {
                  ...user,
                  selected: false,
                };
              }) || []
            );
          } else {
            return data?.results || [];
          }
        });

        const cFetchData: Connection[] = promiseList[0];
        const dtFetchData: DataTable[] = promiseList[1];
        const uFetchData: User[] = promiseList[2];
        unstable_batchedUpdates(() => {
          if (promiseList[0] && promiseList[0].length) {
            setConnectionList(promiseList[0]);
          }
          if (promiseList[1] && promiseList[1].length) {
            setDataTableList(promiseList[1]);
          }
          if (promiseList[2] && promiseList[2].length) {
            setUserList(promiseList[2]);
          }
        });

        if (roleId) {
          getRole(roleId).then((role) => {
            const isAllConn = role?.connectionPermissions?.find(
              (conn) => conn.entityId == null
            );
            const isAllDT = role?.dataTablePermissions?.find(
              (dt) => dt.entityId == null
            );
            unstable_batchedUpdates(() => {
              setName(role?.name || name);

              if (role?.connectionPermissions?.length) {
                if (isAllConn) {
                  setIsAllConnectionPermissions(true);
                  setAllConnectionPermissions(
                    role?.connectionPermissions?.[0].permissions
                  );
                } else {
                  setIsAllConnectionPermissions(false);
                  if (role && role.connectionPermissions) {
                    setConnectionEntityList(
                      role.connectionPermissions.map((conn) => {
                        const connection = cFetchData.find(
                          (connection) =>
                            connection.connectionId === conn.entityId
                        );
                        return {
                          entityName: connection?.name || "",
                          entityId: conn.entityId,
                          write: hasWrite(conn.permissions),
                          uploadTo: true,
                          delete: hasDelete(conn.permissions),
                        };
                      })
                    );
                  }
                }
              }

              if (role?.dataTablePermissions?.length) {
                if (isAllDT) {
                  setIsAllDataTablePermissions(true);
                  setAllDataTablePermissions(
                    role?.dataTablePermissions?.[0].permissions
                  );
                } else {
                  setIsAllDataTablePermissions(false);
                  if (role && role.dataTablePermissions) {
                    setDataTableEntityList(
                      role.dataTablePermissions.map((dt) => {
                        const datatable = dtFetchData.find(
                          (dataTable) => dt.entityId === dataTable.dataTableId
                        );
                        return {
                          entityName: datatable?.name || "",
                          entityId: dt.entityId,
                          write: hasWrite(dt.permissions),
                          upload: hasUpload(dt.permissions),
                          delete: hasDelete(dt.permissions),
                        };
                      })
                    );
                  }
                }
              }

              if (role?.dataTableUploadPermissions?.length) {
                setAllUploadPermissions(
                  role?.dataTableUploadPermissions?.[0].permissions
                );
              }

              if (role?.userPermissions?.length) {
                setAllUserPermissions(role?.userPermissions?.[0].permissions);
              }

              if (role?.userRoles?.length) {
                setUserList(
                  uFetchData.map((user) => {
                    const userRole = role?.userRoles.find(
                      (userRole) => userRole.userId === user.userId
                    );
                    return {
                      ...user,
                      selected: !!userRole,
                    };
                  })
                );
              }
              setLoading(false);
            });
          });
        }
      };

      asyncData();
    },
    /* eslint-disable */
    []
  );

  const createRoleMutation = useMutation(
    (newRole: any) => {
      if (newRole.roleId) {
        return updateRole(newRole);
      }
      return createRole(newRole);
    },
    {
      onSuccess: () => {
        navigate("/roles");
      },
    }
  );

  const deleteMutation = useMutation(
    (roleId: string) => {
      return deleteRole(roleId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("roles");
        navigate("/roles");
      },
    }
  );

  if (loading) {
    return (
      <Flex className={styles.spinnerContainer}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  return (
    <Flex className={styles.mutateContainer}>
      <BreadcrumbBar
        list={
          isCreate
            ? [{ name: "Roles", to: "/roles" }, { name: "Create role" }]
            : [{ name: "Roles", to: "/roles" }, { name }]
        }
      />

      <Heading className={styles.title}>
        {roleId ? "Update role" : "Create role"}
      </Heading>
      <FormControl>
        <Box mb={4}>
          <FormLabel className={styles.label} htmlFor="name">
            Name
          </FormLabel>
          <Input
            id="name"
            w="50%"
            className={styles.input}
            placeholder="Name"
            value={name}
            required={true}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>

        <RenderPermissionBlock
          title="Connection Permissions"
          type="Connections"
          idName="connectionId"
          isAllBool={isAllConnectionPermission}
          allPermStr={allConnectionPermissions}
          entityList={connectionList}
          entityPermList={connectionEntityList}
          setAllPermStr={setAllConnectionPermissions}
          setIsAllBool={setIsAllConnectionPermissions}
          setEntityPermList={setConnectionEntityList}
          permStrList={[
            "Can view all Connections",
            "Can create and edit all Connections",
            "Can delete all Connections",
          ]}
        />
        <RenderPermissionBlock
          title="Data Source Permissions"
          type="Data Sources"
          idName="dataTableId"
          isAllBool={isAllDataTablePermission}
          allPermStr={allDataTablePermissions}
          entityList={dataTableList}
          entityPermList={dataTableEntityList}
          setAllPermStr={setAllDataTablePermissions}
          setIsAllBool={setIsAllDataTablePermissions}
          setEntityPermList={setDataTableEntityList}
          permStrList={[
            "Can view all Data Sources",
            "Can create, edit and upload all Data Sources",
            "Can delete all Data Sources",
          ]}
        />
        <RenderPermissionBlock
          title="User Permissions"
          type="Users"
          idName="userId"
          isAllBool={isAllUserPermission}
          allPermStr={allUserPermissions}
          setAllPermStr={setAllUserPermissions}
          disableSpecific
          permStrList={[
            "Can view Users",
            "Can create and edit Users",
            "Can delete Users",
          ]}
        />

        <FormLabel className={styles.label} mt={6}>
          Assign Role to Users
        </FormLabel>
        <SimpleTable
          className={styles.table}
          data={userList}
          columns={[
            {
              id: "selected",
              name: "Select",
              dataType: TableColumnDataType.Boolean,
            },
            {
              id: "firstName",
              name: "First Name",
              dataType: TableColumnDataType.Text,
            },
            {
              id: "lastName",
              name: "Last Name",
              dataType: TableColumnDataType.Text,
            },
            {
              id: "email",
              name: "Email",
              dataType: TableColumnDataType.Text,
            },
          ]}
          editable={true}
          readOnlyColumns={["firstName", "lastName", "email"]}
          onRowClick={(row: Selectable<User>) => {
            setUserList(
              userList.map((currRow, rowIdx) =>
                row.userId === currRow.userId
                  ? {
                      ...currRow,
                      selected: !currRow.selected,
                    }
                  : currRow
              )
            );
          }}
          onChange={(
            _row: any,
            idx: number,
            column: TableColumn,
            value: any
          ) => {
            const row = userList[idx];
            let newRow = {
              ...row,
              [column.id]: value,
            };

            setUserList(
              userList.map((currRow, rowIdx) =>
                rowIdx === idx ? newRow : currRow
              )
            );
          }}
        />

        {errMsg.length > 0 && (
          <Flex className={styles.errorMessage}>{errMsg}</Flex>
        )}

        <Flex mt={2} justifyContent="space-between">
          <Button
            w={160}
            colorScheme="primaryScheme"
            color="white"
            disabled={name === ""}
            onClick={() => {
              const newRole: NewRole = {
                roleId: isCreate ? null : roleId,
                name: name,
                connectionPermissions: [],
                dataTablePermissions: [],
                dataTableUploadPermissions: [],
                userPermissions: [],
                userRoles: [],
              };

              newRole.connectionPermissions = formatEntity(
                isAllConnectionPermission,
                allConnectionPermissions,
                connectionEntityList
              );
              newRole.dataTablePermissions = formatEntity(
                isAllDataTablePermission,
                allDataTablePermissions,
                dataTableEntityList
              );
              newRole.dataTableUploadPermissions = formatEntity(
                true,
                allUploadPermissions,
                []
              );
              newRole.userPermissions = formatEntity(
                isAllUserPermission,
                allUserPermissions,
                []
              );
              newRole.userRoles = userList.reduce((acc: any[], curr: any) => {
                if (curr.selected) {
                  acc.push({
                    userId: curr.userId,
                  });
                }
                return acc;
              }, []);

              if (isValidRole(newRole)) {
                createRoleMutation.mutate(newRole);
              } else {
                setErrMsg(
                  "Invalid role. Create/update data source requires read permission on connection."
                );
              }
            }}
          >
            {isCreate ? "Create" : "Update"}
          </Button>

          {!isCreate && (
            <DeleteSimpleDialog
              title="Delete role"
              body="Are you sure you want to delete this role?"
              className={styles.deleteBtn}
              canDelete={true}
              isLoading={deleteMutation.isLoading}
              onDelete={() => deleteMutation.mutateAsync(roleId)}
            />
          )}
        </Flex>
      </FormControl>
    </Flex>
  );
}

export default MutateRole;
