import {
  Button,
  CreateToastFnReturn,
  Flex,
  Heading,
  Spinner,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  PaginatedSimpleTable,
  TableColumnDataType,
} from "../components/SimpleTable";

import styles from "./Users.module.scss";
import {
  getMe,
  getUsers,
  resetUserPassword,
  RoleEntityType,
  User,
} from "../api/users-client";
import { canCreate, canWrite } from "../util/permission";
import { PaginatedInfo } from "../api/client";
import { getTenantSubscription } from "../api/tenant-client";
import { useState } from "react";
import { isNullUUID } from "../util/uuid";

const toastOpts = {
  success: {
    description:
      "Password has been successfully reset. A new password has been sent to the users inbox.",
    duration: 3000,
    isClosable: true,
  },
  error: {
    description: "Password reset was unsuccessful. Please try again later.",
    duration: 3000,
    isClosable: true,
  },
  loading: {
    description: "Resetting users password.",
  },
};

function tableActions(
  user: {
    userId: string;
  } | null,
  canWrite: boolean,
  toast: CreateToastFnReturn
) {
  if (user == null) {
    return <div style={{ height: "20px" }}>-</div>;
  }

  return (
    <>
      {canWrite && (
        <Button
          onClick={() => {
            const resetPromise = resetUserPassword(user.userId);
            toast.promise(resetPromise, toastOpts);
          }}
          variant="outline"
          size="xs"
          colorScheme="primaryScheme"
          className={styles.actionButton}
        >
          Reset Password
        </Button>
      )}
    </>
  );
}

function Users() {
  const [paginatedInfo, setPaginatedInfo] = useState<PaginatedInfo | null>(
    null
  );
  const { data: user, isLoading: isLoadingUser } = useQuery("me", getMe);
  const { data: tenantSubscription, isLoading: isLoadingTenantSubscription } =
    useQuery("tenantSubscription", getTenantSubscription, {});
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const toast = useToast();

  if (isLoadingUser) {
    return (
      <Flex className={styles.container}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  let hasSpareUserCount = true;
  if (!isNullUUID(tenantSubscription?.tenantSubscriptionId)) {
    hasSpareUserCount =
      (paginatedInfo?.count || 0) <
      (tenantSubscription?.plan?.allowedUserCount || 0);
  }
  const showCreateBtn = user != null && canCreate(user, RoleEntityType.User);

  return (
    <Flex className={styles.container}>
      <Heading className={styles.title}>Users</Heading>
      {showCreateBtn && (
        <Flex className={styles.createBtnContainer}>
          <Tooltip
            hasArrow
            label="Plan limit reached, please contact sales."
            bg="red.600"
            isDisabled={hasSpareUserCount}
          >
            <Button
              onClick={() => navigate("/users/new")}
              colorScheme="primaryScheme"
              color="white"
              isDisabled={!hasSpareUserCount}
            >
              Create
            </Button>
          </Tooltip>
        </Flex>
      )}
      <Flex className={styles.usersTableContainer}>
        <PaginatedSimpleTable
          setPaginatedInfo={setPaginatedInfo}
          className={styles.table}
          editable={false}
          columns={[
            {
              id: "email",
              name: "Email",
              dataType: TableColumnDataType.Text,
            },
            {
              id: "firstName",
              name: "First Name",
              dataType: TableColumnDataType.Text,
            },
            {
              id: "lastName",
              name: "Last Name",
              dataType: TableColumnDataType.Text,
            },
          ]}
          onRowClick={(user: any) => navigate(`/users/edit/${user.userId}`)}
          getData={getUsers}
          dataName="users"
          transform={(users) =>
            (users?.results || [null, null, null]).map((user: User | null) => {
              return {
                userId: user?.userId || "-",
                email: user?.email || "-",
                firstName: user?.firstName || "-",
                lastName: user?.lastName || "-",
              };
            })
          }
          actions={(table) =>
            tableActions(
              table,
              canWrite(user, RoleEntityType.User, table.userId),
              toast
            )
          }
        />
      </Flex>
    </Flex>
  );
}

export default Users;
