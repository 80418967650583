export enum Features {
  EMAIL_CONNECTION = "email_connection",
}

export function getTenant(): string {
  return window.location.href.split("//")[1].split(".")[0];
}

export function isFeatureEnabled(features: string[], feature: string): boolean {
  return features.includes(feature)
}