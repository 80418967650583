import { useMutation } from "react-query";
import {
  DataTable,
  updateDataTable,
  createDataTable,
} from "../api/data-tables-client";

export function useSaveDataTableMutation(
  dataTableId: string | null,
  onSuccess: (table: DataTable | null) => void
) {
  return useMutation(
    (newDataTable: DataTable) => {
      if (dataTableId) {
        return updateDataTable(newDataTable);
      }
      return createDataTable(newDataTable);
    },
    {
      onSuccess: (data, variables, context) => {
        onSuccess(data);
      },
    }
  );
}
