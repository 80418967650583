import cookies from "browser-cookies";

const accessTokenCookieKey = "access-token";

export function setAccessTokenCookie(value: string | null) {
  if (!value) {
    cookies.erase(accessTokenCookieKey);
    return;
  }

  cookies.set(accessTokenCookieKey, value);
}

export function getAccessTokenCookie(): string | null {
  return cookies.get(accessTokenCookieKey);
}
