import "./App.css";
import { useQuery } from "react-query";
import { useFirebaseAuth } from "./api/firebase";
import AppRouter from "./AppRouter";
import { getTenantId } from "./api/tenant-client";
import LoadingPage from "./components/LoadingPage";
import "bootstrap/dist/css/bootstrap.min.css";

export let TenantId: string | null = null;

export default function App() {
  const { data: tenantId, isLoading: isLoadingTenantId } = useQuery(
    "tenant-id",
    getTenantId,
    {
      refetchInterval: 600000,
    }
  );
  if (isLoadingTenantId) {
    return <LoadingPage />;
  }

  TenantId = tenantId || null;

  return <AppWithTenant tenantId={tenantId!!} />;
}

function AppWithTenant({ tenantId }: { tenantId: string }) {
  const { firstLoad, loading, signOut } = useFirebaseAuth(tenantId);
  if (!firstLoad || loading) {
    return <LoadingPage />;
  }

  return <AppRouter signOut={signOut} tenantId={tenantId!!} />;
}
