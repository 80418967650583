import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import NavBar from "./components/NavBar";
import Connections from "./pages/Connections";
import DataSources from "./pages/DataSources";
import MutateDataSource from "./pages/MutateDataSource";
import MutateConnection from "./pages/MutateConnection";
import { getMe, User } from "./api/users-client";
import Uploads from "./pages/uploads";
import Users from "./pages/Users";
import MutateUser from "./pages/MutateUser";
import { useQuery } from "react-query";
import Home from "./pages/Home";
import { hotjar } from "react-hotjar";
import { getTenant } from "./util/tenant";
import { useState } from "react";
import LoadingPage from "./components/LoadingPage";
import { Mixpanel } from "./analytics/mixpanel";
import ViewDataSource from "./pages/ViewDataSource";
import Roles from "./pages/Roles";
import MutateRole from "./pages/MutateRole";
import { canView, hasWrite } from "./util/permission";

function AppRouter({
  signOut,
  tenantId,
}: {
  signOut: () => void;
  tenantId: string;
}) {
  const { data: user, isLoading: isLoadingUser } = useQuery("me", getMe);
  const [hotJarInited, setHotJarInited] = useState(false);
  if (isLoadingUser) {
    return <LoadingPage />;
  }

  if (user != null && !hotJarInited) {
    hotjar.identify(`${getTenant()}-${user.userId}`, {
      tenant: getTenant(),
    });
    setHotJarInited(true);

    Mixpanel.identify(user.userId);
    Mixpanel.people.set({
      tenant: getTenant(),
    });
  }

  return (
    <Routes>
      <Route
        index
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              {user != null &&
                canView(
                  user,
                  user.permissions?.canReadDataTables || false,
                  ""
                ) ? (
                <Navigate to="/datasources" />
              ) : (
                <Home />
              )}
            </NavBar>
          </RequireAuth>
        }
      />
      <Route
        path="/connections"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <Connections />
            </NavBar>
          </RequireAuth>
        }
      />
      <Route
        path="/connections/new"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <MutateConnection />
            </NavBar>
          </RequireAuth>
        }
      />
      <Route
        path="/connections/edit/:connectionId"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <MutateConnection />
            </NavBar>
          </RequireAuth>
        }
      />

      <Route
        path="/users"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <Users />
            </NavBar>
          </RequireAuth>
        }
      />
      <Route
        path="/roles"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <Roles />
            </NavBar>
          </RequireAuth>
        }
      />
      <Route
        path="/roles/new"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <MutateRole />
            </NavBar>
          </RequireAuth>
        }
      />
      <Route
        path="/roles/edit/:roleId"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <MutateRole />
            </NavBar>
          </RequireAuth>
        }
      />
      <Route
        path="/users/new"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <MutateUser />
            </NavBar>
          </RequireAuth>
        }
      />
      <Route
        path="/users/edit/:userId"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <MutateUser />
            </NavBar>
          </RequireAuth>
        }
      />

      {/* datasource routes */}
      <Route
        path="/datasources"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <DataSources />
            </NavBar>
          </RequireAuth>
        }
      />
      <Route
        path="/datasources/new"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <MutateDataSource />
            </NavBar>
          </RequireAuth>
        }
      />
      <Route
        path="/datasources/:dataTableId"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <ViewDataSource />
            </NavBar>
          </RequireAuth>
        }
      />
      <Route
        path="/datasources/:dataTableId/edit"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <MutateDataSource />
            </NavBar>
          </RequireAuth>
        }
      />
      <Route
        path="/datasources/:dataTableId/upload"
        element={
          <RequireAuth user={user} redirectTo="/login">
            <NavBar signOut={signOut} user={user}>
              <Uploads />
            </NavBar>
          </RequireAuth>
        }
      >
        <Route
          path="/datasources/:dataTableId/upload/:dataTableUploadId"
          element={
            <RequireAuth user={user} redirectTo="/login">
              <NavBar signOut={signOut} user={user}>
                <Uploads />
              </NavBar>
            </RequireAuth>
          }
        />
      </Route>

      <Route
        path="/login"
        element={
          <RequireNoAuth user={user} redirectTo="/datasources">
            <Login tenantId={tenantId} />
          </RequireNoAuth>
        }
      />
    </Routes>
  );
}

function RequireAuth({
  user,
  children,
  redirectTo,
}: {
  user: User | null | undefined;
  children: any;
  redirectTo: string;
}) {
  return user ? children : <Navigate to={redirectTo} />;
}

function RequireNoAuth({
  user,
  children,
  redirectTo,
}: {
  user: User | null | undefined;
  children: any;
  redirectTo: string;
}) {
  return !user ? children : <Navigate to={redirectTo} />;
}

export default AppRouter;
