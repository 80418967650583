import {
  Flex,
  Heading,
  Icon,
  Spinner,
  Button,
  useToast,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import styles from "./UploadFile.module.scss";
import SimpleTable, {
  PaginatedSimpleTable,
  TableColumn,
  TableColumnDataType,
} from "../../../components/SimpleTable";
import { DataTableUpload } from "../../../api/data-table-uploads-client";
import {
  DataTable,
  DataType,
  getDataTableUploadHistory,
} from "../../../api/data-tables-client";
import { ReactNode, useState } from "react";
import UploadDropzone from "../../../components/UploadDropzone";
import { RoleEntityType, User, UserWithRoles } from "../../../api/users-client";
import { PaginatedResponse } from "../../../api/client";
import { MdCheck, MdRefresh } from "react-icons/md";
import classNames from "classnames";
import { canUpload } from "../../../util/permission";
import { capitalizeFirstLetter } from "../../../util/strings";

function renderCustomColumns(
  row: any,
  column: TableColumn,
  value: any,
  onChange: (value: any) => void | null
): ReactNode | null {
  if (TableColumnDataType.Boolean === column.dataType) {
    if (row.required) {
      return <Icon as={MdCheck} />;
    } else {
      return <div></div>;
    }
  }

  return null;
}

export default function UploadFile({
  user,
  dataTableId,
  dataTable,
  newUploadDataTables,
  setNewUploadFile,
  setNewUploadDataTables,
  setNewUploadFileColumnTypes,
  setNewUploadKey,
  setNewUploadFileRows,
  onCancel,
  isActiveTab,
}: {
  user: UserWithRoles;
  dataTableId: string;
  dataTable: DataTable;
  newUploadDataTables: DataTable[] | null;
  setNewUploadFile: (f: File | null) => void;
  setNewUploadDataTables: (dt: DataTable[] | null) => void;
  setNewUploadFileColumnTypes: (fc: { [key: string]: DataType }) => void;
  setNewUploadKey: (s: string | null) => void;
  setNewUploadFileRows: (a: any[]) => void;
  onCancel: () => void;
  isActiveTab: boolean;
}) {
  const [loadingFile, setLoadingFile] = useState<boolean>(false);

  const toast = useToast();

  if (loadingFile || dataTable === null) {
    return (
      <Flex className={styles.spinnerContainer}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  return (
    <Flex className={styles.container} mx={0} px={0}>
      <Flex className={styles.uploadContainer}>
        <UploadDropzone
          canUpload={canUpload(user, RoleEntityType.DataTable, dataTableId)}
          user={user}
          setNewUploadDataTable={(dataTable) => {
            setNewUploadDataTables(
              newUploadDataTables?.sort((a, b) => {
                if (a.dataTableId === dataTable?.dataTableId) return -1;
                else if (b.dataTableId === dataTable?.dataTableId) return 1;
                else return 0;
              }) || null
            );
          }}
          setNewUploadFile={setNewUploadFile}
          setLoadingFile={setLoadingFile}
          setNewUploadFileColumnTypes={setNewUploadFileColumnTypes}
          setNewUploadFileRows={setNewUploadFileRows}
          setNewUploadKey={setNewUploadKey}
          setNewUploadDataTables={setNewUploadDataTables}
          onFail={() => {
            toast({
              description:
                "Oops! It looks like you uploaded an incompatible file type. Please upload a file in either CSV (.csv) or Excel (.xlsx,.xls) format, and we'll be all set to go!",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }}
          isNewDataTable={false}
        />
        <SimpleTable
          className={classNames(styles.table, styles.expectedColumnsTable)}
          data={dataTable.columns.map((column) => {
            return {
              columnName: column.name,
              required: column.isRequired,
            };
          })}
          editable={false}
          columns={[
            {
              id: "columnName",
              name: "Expected Columns",
              dataType: TableColumnDataType.Text,
            },
            {
              id: "required",
              name: " Required",
              dataType: TableColumnDataType.Boolean,
            },
          ]}
          columnRenderer={renderCustomColumns}
        />
      </Flex>
      <Flex direction="column" className={styles.dataTableContainer}>
        <Heading as="h2" size="md" p={0} m={0} mb={2}>
          Previous Uploads
        </Heading>
        <PaginatedSimpleTable
          className={styles.table}
          editable={false}
          columns={[
            {
              id: "dataSource",
              name: "Data Source",
              dataType: TableColumnDataType.Text,
            },
            {
              id: "fileName",
              name: "File",
              dataType: TableColumnDataType.Text,
            },
            {
              id: "mode",
              name: "Mode",
              dataType: TableColumnDataType.Text,
            },
            {
              id: "status",
              name: "Status",
              dataType: TableColumnDataType.Text,
            },
            {
              id: "error",
              name: "Error",
              dataType: TableColumnDataType.Text,
            },
            {
              id: "createdAtUtc",
              name: "Created At",
              dataType: TableColumnDataType.Date,
            },
          ]}
          getData={getDataTableUploadHistory.bind(null, dataTableId!)}
          dataName="dataTableUploads"
          transform={(uploads: PaginatedResponse<DataTableUpload> | null) =>
            (uploads?.results || [null, null, null]).map((upload) => {
              return {
                dataTableUploadId: upload?.dataTableUploadId || "-",
                dataSource: upload?.dataTable?.name || "-",
                fileName: upload?.fileName || "-",
                mode: capitalizeFirstLetter(upload?.uploadMode || "-"),
                status: capitalizeFirstLetter(upload?.status || "-"),
                error: upload?.failureMessage || "-",
                createdAtUtc: upload?.createdAtUtc || "-",
              };
            })
          }
          colWidths={[null, null, 10, 40, null]}
          disableFetch={!isActiveTab}
        />
      </Flex>

      <Button
        onClick={onCancel}
        w={130}
        fontWeight="normal"
        variant="outline"
        size="md"
      >
        Cancel
      </Button>
    </Flex>
  );
}
