import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

import styles from "./Breadcrumb.module.scss";
import { Link } from 'react-router-dom';

interface BreadcrumbListItem {
  to?: string;
  name: string;
}

function BreadcrumbBar({
  list,
}: {
  list: BreadcrumbListItem[];
}) {
  return (
    <Breadcrumb className={styles.breadcrumb} spacing='6px' separator={<ChevronRightIcon color='gray.500' />}>
      {list.map((item, idx) => (
        <BreadcrumbItem key={item.name} isCurrentPage={idx === list.length - 1}>
          <BreadcrumbLink as={(idx === list.length - 1) ? undefined : Link} to={item.to}>{item.name}</BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}

export default BreadcrumbBar;