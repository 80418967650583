import { unstable_batchedUpdates } from "react-dom";
import { useMutation } from "react-query";
import {
  createDataTableUpload,
  DataTableUploadStatus,
  DataTableUploadMode,
  DataTableUpload,
  importUpload,
  validateUpload,
  getUploadById,
} from "../api/data-table-uploads-client";
import { DataTable } from "../api/data-tables-client";
import { HttpStatusCode } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/toast";
import { waitUntil } from "../util/condition";
import { UploadService, useUploadService } from "../services/upload-service";

// not a mutation function but didn't want to alter this as this is a hot fix for create & upload on create data source
export async function createUpload(
  dataTable: DataTable,
  file: File | null,
  uploadKey: string,
  uploadMode: DataTableUploadMode,
  toast: CreateToastFnReturn,
  uploadService: UploadService
): Promise<DataTableUpload | null> {
  if (file == null) {
    throw new Error("no data table or file");
  }
  const response = await createDataTableUpload({
    dataTableUploadId: null,
    fileKey: uploadKey,
    fileName: file.name,
    dataTable: null,
    dataTableId: dataTable.dataTableId,
    status: DataTableUploadStatus.PENDING,
    failureMessage: null,
    uploadMode: uploadMode,
    failureCount: 0,
    rowCount: 0,
    rowFailureCount: 0,
    progress: null,
    createdAtUtc: new Date(),
    updatedAtUtc: new Date(),
  });
  const uploadId = response.data?.dataTableUploadId;

  if (response.statusCode === HttpStatusCode.Conflict) {
    toast({
      description:
        "Oops! It looks like a file with the same name has already been processed. Please use a different file name or upload in 'Overwrite' mode",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    return null;
  } else if (response.error != null) {
    throw response.error;
  } else if (!uploadId) {
    toast({
      description:
        "Oops! Something went wrong while creating the upload. Please try again.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    return null;
  }

  if (!(await uploadService.requestImportAndWait(uploadId))) {
    console.log("failed to import");

    toast({
      description: "Failed to import file",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    return null;
  }

  if (!(await uploadService.requestValidateAndWait(uploadId))) {
    console.log("failed to validate");

    toast({
      description: "Failed to validate file",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  }

  return uploadService.refreshProcessingUpload();
}
