import { Flex } from "@chakra-ui/react";
import { mdiUpload } from "@mdi/js";
import Dropzone from "react-dropzone-uploader";
import { User, UserWithRoles } from "../api/users-client";
import * as Mdi from "@mdi/react";

import styles from "./UploadDropzone.module.scss";
import { DataTable, DataType } from "../api/data-tables-client";
import { unstable_batchedUpdates } from "react-dom";
import {
  createUploadUrl,
  readUpload,
  uploadFile,
  zipFile,
} from "../api/data-table-uploads-client";
import { uuidv4 } from "../util/uuid";
import { useUploadService } from "../services/upload-service";

function UploadDropzone({
  canUpload,
  user,
  setLoadingFile,
  setNewUploadDataTable,
  setNewUploadFile,
  setNewUploadKey,
  setNewUploadFileColumnTypes,
  setNewUploadFileRows,
  setNewUploadDataTables,
  onFail = undefined,
  isNewDataTable,
}: {
  canUpload: boolean;
  user: User | UserWithRoles | null;
  setLoadingFile: (b: boolean) => void;
  setNewUploadDataTable: (t: DataTable | null) => void;
  setNewUploadFile: (f: File) => void;
  setNewUploadKey: (k: string | null) => void;
  setNewUploadFileColumnTypes: (
    types: { [key: string]: DataType },
    indexes: { [key: string]: number }
  ) => void;
  setNewUploadFileRows: (rows: any[]) => void;
  setNewUploadDataTables: (tables: DataTable[]) => void;
  onFail?: (failStatus: string) => void;
  isNewDataTable: boolean;
}) {
  const uploadService = useUploadService();
  // called every time a file's `status` changes
  const handleChangeStatus = async (
    { meta, file }: { meta: any; file: File },
    status
  ) => {
    switch (status) {
      case "preparing": {
        setLoadingFile(true);
        break;
      }
      case "done": {
        const isZip =
          file.type === "application/zip" || file.name.endsWith(".zip");
        const zippedFile = isZip ? file : await zipFile(file);
        const fileSize = isZip ? file.size : zippedFile.size;
        const fileName = isZip ? file.name : `${file.name}.zip`;

        uploadService.setProcessingUpload(null);

        const uploadUrlAndKey = await createUploadUrl(fileName, fileSize);
        if (!uploadUrlAndKey) {
          onFail && onFail("failed to upload file");
          return;
        }

        await uploadFile(uploadUrlAndKey!!.uploadUrl, zippedFile);

        const uploadData = await readUpload(
          uploadUrlAndKey.key,
          fileName,
          isNewDataTable
        );
        unstable_batchedUpdates(() => {
          setNewUploadDataTable(
            (uploadData?.tables?.length || 0) > 0
              ? uploadData!!.tables[0]
              : null
          );
          setNewUploadFile(file);
          setNewUploadKey(uploadData?.key || null);
          setNewUploadFileColumnTypes(
            uploadData?.typeByColumn || {},
            uploadData?.indexByColumn || {}
          );
          setNewUploadFileRows(uploadData?.rows || []);
          setNewUploadDataTables(uploadData?.tables || []);
          setLoadingFile(false);
        });
        break;
      }
      case "rejected_file_type": {
        onFail && onFail(status);
        break;
      }
    }
  };

  return user != null && canUpload ? (
    <Dropzone
      inputContent={
        <Flex className={styles.dropzoneInputContainer}>
          <Mdi.Icon path={mdiUpload} />
          <span>Drag files or Click to Browse</span>
          <span className={styles.smallText}>Files supported: CSV, EXCEL</span>
        </Flex>
      }
      classNames={{
        inputLabel: styles.dropzoneInputLabel,
      }}
      addClassNames={{ dropzone: styles.dropzone }}
      onChangeStatus={(props, status) => {
        handleChangeStatus(props, status);
        return;
      }}
      // accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.binary.macroEnabled.12,application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12"
      accept="text/csv,.xlsx,.xls,.zip"
    />
  ) : (
    <></>
  );
}

export default UploadDropzone;
