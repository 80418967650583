import axios, { AxiosError, Method } from "axios";
import fileDownload from "js-file-download";
import { TenantId } from "../App";
import { apiConfig } from "../config/api";
import { getAccessToken } from "./firebase";

export const pageLimit = 10;
export const smallPageLimit = 5;

const dateTimeReviver = (key, value) => {
  if (key === "createdAtUtc" || key === "updatedAtUtc") {
    return new Date(value);
  }
  return value;
};

axios.defaults.transformResponse = [
  function transformResponse(data, headers) {
    // Optionally you can check the response headers['content-type'] for application/json or text/json
    return JSON.parse(data, dateTimeReviver);
  },
];

export interface Result<T> {
  data: T | null;
  statusCode: number | null;
  error: any | null;
}

export async function makeRequest<T>(
  method: Method,
  path: string,
  params: any | null = null,
  body: any | null = null,
  headers: any = {}
): Promise<T | null> {
  const accessToken = await getAccessToken(TenantId);
  if (accessToken == null) {
    return null;
  }

  const res = await makeRequestWrapResult<T>(method, path, params, body, headers);
  if (res.error) {
    throw res.error;
  }

  return res.data;
}

export async function makeRequestWrapResult<T>(
  method: Method,
  path: string,
  params: any | null = null,
  body: any | null = null,
  headers: any = {}
): Promise<Result<T>> {
  if (method === "GET" && body) {
    return { data: null, error: new Error("cannot send body with get"), statusCode: null };
  }

  const accessToken = await getAccessToken(TenantId);
  if (accessToken == null) {
    return { data: null, error: new Error("missing auth"), statusCode: null };
  }

  try {
    const res = await axios.request({
      url: `${apiConfig.baseUrl}${path}`,
      method: method,
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      },
      params: params,
      data: body,
    });

    return { data: res.data, statusCode: res.status, error: null };
  } catch (err) {
    if (axios.isAxiosError(err)) {
      return { data: err.response?.data, error: err, statusCode: err.response?.status || null };
    }

    return { data: null, error: err, statusCode: null };
  }
}

export async function makeRequestNoAuth<T>(
  method: Method,
  path: string,
  params: any | null = null,
  body: any | null = null
): Promise<T | null> {
  if (method === "GET" && body) {
    throw new Error("cannot send body with get");
  }

  const res = await axios.request({
    url: `${apiConfig.baseUrl}${path}`,
    method: method,
    params: params,
    data: body,
  });

  return res.data;
}

export interface PaginatedInfo {
  count: number;
  pageTotal: number;
  pageCurrent: number;
  pageSize: number;
}

export interface PaginatedResponse<T> {
  count: number;
  pageTotal: number;
  pageCurrent: number;
  pageSize: number;
  results: T[];
}

export interface SuccessResponse {
  message: string;
}
