import { trackEvent } from "../analytics/tracker";
import {
  makeRequest,
  pageLimit,
  PaginatedResponse,
  SuccessResponse,
} from "./client";

export async function getAzureContainersByConnection(
  connectionId: string
): Promise<string[] | null> {
  return makeRequest<string[]>(
    "GET",
    `/api/connections/${connectionId}/azure_containers`
  );
}
export async function getAzureContainers(
  accountName: string,
  accountKey: string,
  endpointSuffix: string,
  defaultEndpointsProtocol: string
): Promise<string[] | null> {
  return makeRequest<string[]>("GET", "/api/connections/azure_containers", {
    account_name: accountName,
    account_key: accountKey,
    endpoint_suffix: endpointSuffix,
    default_endpoints_protocol: defaultEndpointsProtocol,
  });
}

export async function getS3BucketsByConnection(
  connectionId: string
): Promise<string[] | null> {
  return makeRequest<string[]>(
    "GET",
    `/api/connections/${connectionId}/s3_buckets`
  );
}

export async function getS3Buckets(
  region: string,
  endpoint: string,
  accessKeyId: string,
  secretAccessKey: string
): Promise<string[] | null> {
  if (
    region === "" ||
    accessKeyId === "" ||
    secretAccessKey === "" ||
    endpoint === ""
  ) {
    return [];
  }

  return makeRequest<string[]>("GET", "/api/connections/s3_buckets", {
    region: region,
    access_key_id: accessKeyId,
    secret_access_key: secretAccessKey,
    endpoint: endpoint,
  });
}

export async function createConnection(
  conn: Connection
): Promise<Connection | null> {
  trackEvent("connection_created", {
    connection_name: conn.dataSourceName,
    connection_type: conn.connectionType,
  });
  return makeRequest<Connection>("POST", "/api/connections", null, conn);
}

export async function updateConnection(
  conn: Connection
): Promise<Connection | null> {
  return makeRequest<Connection>(
    "PUT",
    `/api/connections/${conn.connectionId}`,
    null,
    conn
  );
}

export async function getConnection(id: string): Promise<Connection | null> {
  return makeRequest<Connection>("GET", `/api/connections/${id}`);
}

export async function deleteConnection(
  id: string
): Promise<SuccessResponse | null> {
  return makeRequest<SuccessResponse>("DELETE", `/api/connections/${id}`);
}

export async function getConnections(
  offset: number,
  limit: number = pageLimit
): Promise<PaginatedResponse<Connection> | null> {
  return makeRequest("GET", "/api/connections", {
    limit: limit,
    offset: offset * limit,
  });
}
export enum ConnectionType {
  S3 = "S3",
  Blob = "BLOB",
  Email = "EMAIL",
}

export interface ConnectionDataTable {
  dataTableId: string;
  name: string;
}

export interface Connection {
  connectionId: string;
  name: string;
  description: string | null;
  dataSourceHost: string;
  dataSourceUsername: string;
  dataSourcePassword: string;
  dataSourceRegion: string | null;
  dataSourceName: string;
  dataSourceProtocol: string | null;
  connectionType: ConnectionType;
  dataTables: ConnectionDataTable[];
  createdAtUtc: Date;
  updatedAtUtc: Date;
}
