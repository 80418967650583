import { Role } from "../api/roles-client";
import { RoleEntityType, UserPermissions, UserWithRoles } from "../api/users-client";
import { isNullUUID } from "./uuid";

const entityTypeMap = {
  [RoleEntityType.Connection]: "connectionPermissions",
  [RoleEntityType.DataTable]: "dataTablePermissions",
  [RoleEntityType.DataTableUpload]: "dataTableUploadPermissions",
  [RoleEntityType.User]: "userPermissions",
};

export enum Permission {
  Read = "r",
  Write = "w",
  Delete = "d",
  Export = "e",
  Upload = "u",
}

export function hasRead(permissions: string): boolean {
  return permissions.indexOf(Permission.Read) !== -1;
}

export function hasWrite(permissions: string): boolean {
  return permissions.indexOf(Permission.Write) !== -1;
}

export function hasDelete(permissions: string): boolean {
  return permissions.indexOf(Permission.Delete) !== -1;
}

export function hasExport(permissions: string): boolean {
  return permissions.indexOf(Permission.Export) !== -1;
}

export function hasUpload(permissions: string): boolean {
  return permissions.indexOf(Permission.Upload) !== -1;
}

export function canView(
  user: UserWithRoles | null | undefined,
  canView: boolean,
  permissions: string
): boolean {
  if (user?.owner === true) {
    return true;
  } else if (user?.permissions?.roleCount === 0) {
    return hasRead(permissions);
  } else {
    return canView === true;
  }
}

export function canRead(
  user: UserWithRoles | null | undefined,
  entityType: RoleEntityType,
  entityId: string
): boolean {
  if (user?.owner === true) {
    return true;
  }

  const roles = user?.roles || [];
  let entityPermissions = roles.reduce((list: any[], role) => {
    return list.concat(role[entityTypeMap[entityType]]);
  }, []).filter(e => e);

  for (let entityPerm of entityPermissions) {
    const entityMatch =
      entityPerm.entityId == null || entityPerm.entityId === entityId;
    if (entityMatch && hasRead(entityPerm.permissions)) return true;
  }
  return false;
}

export function canCreate(user: UserWithRoles | null | undefined, entityType: RoleEntityType): boolean {
  if (user?.owner === true) {
    return true;
  }

  const roles = user?.roles || [];

  let entityPermissions = roles.reduce((list: any[], role) => {
    return list.concat(role[entityTypeMap[entityType]]);
  }, []).filter(e => e);

  for (let entityPerm of entityPermissions) {
    if (entityPerm.entityId == null && hasWrite(entityPerm.permissions)) {
      return true;
    }
  }
  return false;
}

export function canWrite(
  user: UserWithRoles | null | undefined,
  entityType: RoleEntityType,
  entityId: string
): boolean {
  if (user?.owner === true) {
    return true;
  }

  const roles = user?.roles || [];

  let entityPermissions: any[] = roles.reduce((list: any[], role) => {
    return list.concat(role[entityTypeMap[entityType]]);
  }, []).filter(e => e);

  for (let entityPerm of entityPermissions) {
    const entityMatch =
      entityPerm.entityId == null || entityPerm.entityId === entityId;
    if (entityMatch && hasWrite(entityPerm.permissions)) return true;
  }
  return false;
}

export function canDelete(
  user: UserWithRoles | null | undefined,
  entityType: RoleEntityType,
  entityId: string
): boolean {
  if (user?.owner === true) {
    return true;
  }

  const roles = user?.roles || [];

  let entityPermissions: any[] = roles.reduce((list: any[], role) => {
    return list.concat(role[entityTypeMap[entityType]]);
  }, []).filter(e => e);

  for (let entityPerm of entityPermissions) {
    const entityMatch =
      entityPerm.entityId == null || entityPerm.entityId === entityId;
    if (entityMatch && hasDelete(entityPerm.permissions)) return true;
  }
  return false;
}

export function canUpload(
  user: UserWithRoles | null | undefined,
  entityType: RoleEntityType,
  entityId: string
): boolean {
  if (user?.owner === true) {
    return true;
  }

  const roles = user?.roles || [];

  let entityPermissions: any[] = roles.reduce((list: any[], role) => {
    return list.concat(role[entityTypeMap[entityType]]);
  }, []).filter(e => e);

  for (let entityPerm of entityPermissions) {
    const entityMatch =
      entityPerm.entityId == null || entityPerm.entityId === entityId;
    if (entityMatch && hasUpload(entityPerm.permissions)) return true;
  }
  return false;
}
