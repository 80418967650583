import {
  Button,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  DataTable,
  duplicateDataTable,
  getDataTableDataLink,
  getDataTables,
  getDataTableTemplateLink,
} from "../api/data-tables-client";
import { RoleEntityType, getMe } from "../api/users-client";
import {
  PaginatedSimpleTable,
  TableColumnDataType,
} from "../components/SimpleTable";

import styles from "./DataSources.module.scss";
import { canCreate } from "../util/permission";
import { getTenantSubscription } from "../api/tenant-client";
import { useState } from "react";
import { PaginatedInfo } from "../api/client";
import { isNullUUID } from "../util/uuid";
import {
  HamburgerIcon,
  AddIcon,
  ExternalLinkIcon,
  RepeatIcon,
  EditIcon,
  CopyIcon,
} from "@chakra-ui/icons";

function DataSources() {
  const navigate = useNavigate();

  const [paginatedInfo, setPaginatedInfo] = useState<PaginatedInfo | null>(
    null
  );

  const { data: user, isLoading: isLoadingUser } = useQuery("me", getMe);
  const { data: tenantSubscription, isLoading: isLoadingTenantSubscription } =
    useQuery("tenantSubscription", getTenantSubscription, {});

  const queryClient = useQueryClient();

  if (isLoadingUser || isLoadingTenantSubscription) {
    return (
      <Flex className={styles.spinnerContainer}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  let hasSpareDataTableCount = true;
  if (
    !isNullUUID(
      tenantSubscription?.tenantSubscriptionId
    )
  ) {
    hasSpareDataTableCount =
      (paginatedInfo?.count || 0) <
      (tenantSubscription?.plan?.allowedDataTableCount || 0);
  }
  const showCreateBtn =
    user != null && canCreate(user, RoleEntityType.DataTable);

  return (
    <Flex className={styles.container}>
      <Heading className={styles.title}>Data Sources</Heading>
      {showCreateBtn && (
        <Flex className={styles.createBtnContainer}>
          <Tooltip
            hasArrow
            label="Plan limit reached, please contact sales."
            bg="red.600"
            isDisabled={hasSpareDataTableCount}
          >
            <Button
              onClick={() => navigate("/datasources/new")}
              colorScheme="primaryScheme"
              color="white"
              isDisabled={!hasSpareDataTableCount}
            >
              Create
            </Button>
          </Tooltip>
        </Flex>
      )}
      <Flex className={styles.dataTableContainer}>
        <PaginatedSimpleTable
          setPaginatedInfo={setPaginatedInfo}
          className={styles.table}
          columns={[
            { id: "name", name: "Name", dataType: TableColumnDataType.Text },
            {
              id: "outputType",
              name: "Output Type",
              dataType: TableColumnDataType.Text,
            },
            {
              id: "outputPath",
              name: "Output Path",
              dataType: TableColumnDataType.Text,
            },
            {
              id: "createdAtUtc",
              name: "Created At",
              dataType: TableColumnDataType.Date,
            },
            {
              id: "updatedAtUtc",
              name: "Updated At",
              dataType: TableColumnDataType.Date,
            },
          ]}
          getData={getDataTables}
          dataName="dataTables"
          actions={(table: DataTable) => (
            <>
              <Tooltip label="Duplicate Template" fontSize="sm">
                <IconButton
                  aria-label="duplicate"
                  variant="outline"
                  onClick={async () => {
                    const newDataTable = await duplicateDataTable(
                      table.dataTableId
                    );

                    if (newDataTable != null) {
                      navigate(`/datasources/${newDataTable.dataTableId}`);
                    }
                  }}
                  icon={<CopyIcon />}
                />
              </Tooltip>
            </>
          )}
          transform={(data) =>
            (data?.results || [null, null, null]).map((table) => ({
              dataTableId: table?.dataTableId || "-",
              name: table?.name || "-",
              outputType: table?.outputType || "-",
              outputPath: table?.outputPath || "-",
              createdAtUtc: table?.createdAtUtc || "-",
              updatedAtUtc: table?.updatedAtUtc || "-",

              hasTemplate: table?.templateKey != null,
              hasData: table?.latestUploadKey != null,
            }))
          }
          editable={false}
          onRowClick={(dataTable: any) =>
            navigate(`/datasources/${dataTable.dataTableId}`)
          }
          colWidths={[null, 10, null, null]}
        />
      </Flex>
    </Flex>
  );
}

export default DataSources;
