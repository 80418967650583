const firebaseConfig = {
  apiKey: "AIzaSyDm_4tbR1RXcg4EgjouhxFtz02z8x5zens",
  authDomain: "reference-data-suite.firebaseapp.com",
  projectId: "reference-data-suite",
  storageBucket: "reference-data-suite.appspot.com",
  messagingSenderId: "782587174426",
  appId: "1:782587174426:web:18ca721c060c8bdf0afba3",
  measurementId: "G-N2FBQDZ48H",
};

export default firebaseConfig;
