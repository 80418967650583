import { makeRequest, makeRequestNoAuth } from "./client";

export async function getTenantId(): Promise<string | null> {
  const resp = await makeRequestNoAuth<TenantResponse | null>(
    "GET",
    "/api/tenant"
  );

  return resp?.tenant || null;
}

export async function getTenantFeatures(): Promise<string[] | null> {
  return await makeRequestNoAuth("GET", "/api/tenant/features")
}

export async function getTenantSubscription(): Promise<TenantSubscription | null> {
  return await makeRequest("GET", "/api/subscription")
}

interface TenantResponse {
  tenant: string;
}

interface Plan {
  planId: string
  name: string
  allowedUserCount: number
  allowedDataTableCount: number
  features: string[]
  createdAtUtc: string
  updatedAtUtc: string
}
export interface TenantSubscription {
  tenantSubscriptionId: string
  tenantId: string
  trial: boolean
  planId: string
  plan: Plan
  expiresAt: string
  createdAt: string
  updatedAt: string
}
