import classNames from "classnames";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";

import styles from "./DestinationBox.module.scss";
import { ConnectionType } from "../api/connections-client";

function getImageUrl(type: ConnectionType) {
  switch (type) {
    case ConnectionType.S3:
      return "/s3.webp";
    case ConnectionType.Blob:
      return "/blob.png";
    case ConnectionType.Email:
      return "/email.svg";
  }
}

export function DestinationInfoBox({
  type,
  name,
  description,
  path,
  onClick,
}: {
  type: ConnectionType;
  name: string;
  description: string;
  path: string;
  onClick: () => void;
}) {
  return (
    <Flex
      onClick={() => onClick()}
      alignItems="center"
      className={styles.infoContainer}
    >
      <Image className={styles.infoImage} src={getImageUrl(type)} />
      <Flex direction="column" justify="space-between">
        <Box>
          <Heading className={styles.infoHeader}>{name}</Heading>
          <Text noOfLines={1} className={styles.infoDesc}>
            {description}
          </Text>
        </Box>

        <Text m={0} p={0} className={styles.infoPath}>
          <strong>Path:&nbsp;</strong>
          {path}
        </Text>
      </Flex>
    </Flex>
  );
}

function DestinationBox({
  imageUrl,
  name,
  className,
  type,
  currentType,
  id,
  setDestination,
}: {
  imageUrl: string;
  name: string;
  className: string;
  type: ConnectionType;
  currentType: ConnectionType | null;
  id: string;
  setDestination: (DestinationType) => void;
}) {
  return (
    <Flex
      onClick={() => setDestination(type)}
      id={id}
      className={classNames(styles.container, className)}
    >
      <Box
        className={classNames(
          styles.box,
          type === currentType ? styles.boxSelected : null
        )}
        borderWidth="1px"
      >
        <Image className={styles.image} src={imageUrl} />
        {name}
      </Box>
    </Flex>
  );
}

export default DestinationBox;
