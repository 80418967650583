import { Flex } from "@chakra-ui/react";

import styles from "./EmailConnectionForm.module.scss";
import FormInput from "./form/FormInput";
import classNames from "classnames";

function EmailConnectionForm({
  className = undefined,
  email,
  subject,
  body,
  setEmail,
  setSubject,
  setBody,
  setValid,
}: {
  className?: string;
  email: string;
  subject: string;
  body: string;
  setEmail: (string) => void;
  setSubject: (string) => void;
  setBody: (string) => void;
  setValid: (boolean) => void;
}) {
  setValid(email !== "" && subject !== "" && body !== "");

  return (
    <Flex className={classNames(styles.container, className)}>
      <FormInput
        id="email"
        label="Email Address"
        type="email"
        value={email}
        placeholder="email@email.com"
        onChange={(e) => {
          setEmail(e.currentTarget.value);
        }}
        isRequired
      />
      <FormInput
        id="subject"
        label="Subject"
        value={subject}
        placeholder="Subject placeholder..."
        onChange={(e) => {
          setSubject(e.currentTarget.value);
        }}
        isRequired
      />
      <FormInput
        id="body"
        label="Body"
        value={body}
        placeholder="Body placeholder..."
        onChange={(e) => {
          setBody(e.currentTarget.value);
        }}
        isRequired
      />
    </Flex>
  );
}

export default EmailConnectionForm;
