import classNames from "classnames";
import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react";

import styles from "./S3ConnectionForm.module.scss";
import {
  getS3Buckets,
  getS3BucketsByConnection,
} from "../api/connections-client";
import { useQuery } from "react-query";

function S3ConnectionForm({
  className = undefined,
  endpoint,
  accessKeyId,
  secretAccessKey,
  bucketName,
  region,
  connectionId,
  setEndpoint,
  setAccessKeyId,
  setSecretAccessKey,
  setBucketName,
  setRegion,
  setValid,
}: {
  className?: string;
  endpoint: string;
  accessKeyId: string;
  secretAccessKey: string;
  bucketName: string;
  region: string;
  connectionId: string | null;
  setEndpoint: (string) => void;
  setAccessKeyId: (string) => void;
  setSecretAccessKey: (string) => void;
  setBucketName: (string) => void;
  setRegion: (string) => void;
  setValid: (boolean) => void;
}) {
  const buckets = useQuery(
    `${endpoint}-${region}-${accessKeyId}-${secretAccessKey}`,
    () =>
      connectionId
        ? getS3BucketsByConnection(connectionId)
        : getS3Buckets(region, endpoint, accessKeyId, secretAccessKey)
  );

  if (endpoint === "") {
    setEndpoint(`https://s3.us-east-1.amazonaws.com`);
    return <div></div>;
  }

  if (region === "" && endpoint.indexOf(".") !== -1) {
    setRegion(
      endpoint.replaceAll("https://", "").replaceAll("s3.", "").split(".")[0]
    );
  }

  const hasBuckets = (buckets.data?.length || 0) > 0;
  setValid(
    bucketName !== "" &&
      accessKeyId !== "" &&
      secretAccessKey !== "" &&
      endpoint !== ""
  );

  if (hasBuckets && bucketName === "") {
    setBucketName(buckets.data!![0]);
  }

  return (
    <FormControl className={classNames(styles.container, className)}>
      <FormLabel className={styles.label} htmlFor="region">
        Region
      </FormLabel>
      <Select
        id="region"
        className={styles.input}
        value={region}
        onChange={(e) => {
          setEndpoint(`https://s3.${e.currentTarget.value}.amazonaws.com`);
          setRegion(e.currentTarget.value);
        }}
      >
        <option value={"us-east-1"}>N. Virginia (us-east-1)</option>
        <option value={"us-west-1"}>N. California (us-west-1)</option>
        <option value={"us-west-2"}>Oregon (us-west-2)</option>
        <option value={"eu-west-1"}>Ireland (eu-west-1)</option>
        <option value={"eu-west-2"}>London (eu-west-2)</option>
        <option value={"eu-central-1"}>Frankfurt (eu-central-1)</option>
        <option value={"ap-southeast-1"}>Singapore (ap-southeast-1)</option>
        <option value={"ap-southeast-2"}>Sydney (ap-southeast-2)</option>
        <option value={"ap-northeast-1"}>Tokyo (ap-northeast-1)</option>
        <option value={"ap-northeast-2"}>Seoul (ap-northeast-2)</option>
        <option value={"sa-east-1"}>Saõ Paulo (sa-east-1)</option>
        <option value={"cn-north-1"}>Beijing (cn-north-1)</option>
      </Select>
      <FormLabel className={styles.label} htmlFor="endpoint">
        Endpoint
      </FormLabel>
      <Input
        className={styles.input}
        id="endpoint"
        type="text"
        value={endpoint}
        onChange={(e) => {
          setEndpoint(e.currentTarget.value);
        }}
        placeholder="Endpoint"
        required={true}
      />
      <FormLabel className={styles.label} htmlFor="accessKeyId">
        Access Key ID
      </FormLabel>
      <Input
        className={styles.input}
        id="accessKeyId"
        type="text"
        value={accessKeyId}
        onChange={(e) => {
          setAccessKeyId(e.currentTarget.value);
        }}
        placeholder="AWS Access Key ID"
        required={true}
      />
      <FormLabel className={styles.label} htmlFor="secretAccessKey">
        Secret Access Key
      </FormLabel>
      <Input
        className={styles.input}
        id="secretAccessKey"
        type="password"
        value={secretAccessKey}
        onChange={(e) => {
          setSecretAccessKey(e.currentTarget.value);
        }}
        placeholder="AWS Secret Access Key"
        required={true}
      />
      <FormLabel className={styles.label} htmlFor="bucket">
        Bucket
      </FormLabel>
      <Select
        id="bucket"
        disabled={!hasBuckets}
        value={bucketName}
        className={styles.input}
        placeholder={hasBuckets ? undefined : "Check connection settings"}
        onChange={(e) => setBucketName(e.currentTarget.value)}
      >
        {(buckets.data || []).map((b) => (
          <option key={b} value={b}>
            {b}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

export default S3ConnectionForm;
