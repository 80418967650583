import { Button, Flex, Heading, Spinner } from "@chakra-ui/react";
import { QueryClient, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { deleteConnection, getConnections } from "../api/connections-client";
import { RoleEntityType, getMe } from "../api/users-client";
import SimpleTable, {
  PaginatedSimpleTable,
  TableColumnDataType,
} from "../components/SimpleTable";

import styles from "./Connections.module.scss";
import { canCreate } from "../util/permission";

function actions(
  conn: {
    connectionId: string;
  } | null
) {
  if (conn == null) {
    return <div style={{ height: "20px" }}>-</div>;
  }

  return <></>;
}

function Connections() {
  const { data: user, isLoading: isLoadingUser } = useQuery("me", getMe);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  if (isLoadingUser) {
    return (
      <Flex className={styles.spinnerContainer}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  return (
    <Flex className={styles.container}>
      <Heading size="xl" className={styles.title}>
        Connections
      </Heading>
      {user != null && canCreate(user, RoleEntityType.Connection) && (
        <Flex className={styles.createBtnContainer}>
          <Button
            onClick={() => navigate("/connections/new")}
            colorScheme="primaryScheme"
            color="white"
          >
            Create
          </Button>
        </Flex>
      )}
      <Flex className={styles.dataTableContainer}>
        <PaginatedSimpleTable
          className={styles.table}
          editable={false}
          columns={[
            { id: "name", name: "Name", dataType: TableColumnDataType.Text },
            { id: "type", name: "Type", dataType: TableColumnDataType.Text },
            {
              id: "createdAtUtc",
              name: "Created At",
              dataType: TableColumnDataType.Date,
            },
          ]}
          getData={getConnections}
          dataName="connections"
          transform={(connections) =>
            (connections?.results || []).map((conn) => ({
              connectionId: conn.connectionId,
              name: conn.name,
              type: conn.connectionType,
              createdAtUtc: conn.createdAtUtc,
            }))
          }
          onRowClick={(row) =>
            navigate(`/connections/edit/${row.connectionId}`)
          }
          colWidths={[null, 10, null]}
        />
      </Flex>
    </Flex>
  );
}

export default Connections;
