import {
  makeRequest,
  pageLimit,
  PaginatedResponse,
  SuccessResponse,
} from "./client";
import { DataType } from "./data-tables-client";

export async function evaluateScript(
  columns: string[],
  values: { [key: string]: string },
  script: string,
  dataType: DataType
): Promise<EvaluateScriptResonse | null> {
  return makeRequest<EvaluateScriptResonse>(
    "POST",
    "/api/script/evaluate",
    null,
    {
      columns: columns,
      values: values,
      dataType: dataType,
      script: script,
    }
  );
}

export interface EvaluateScriptResonse {
  result: any | null;
  errorMessage: string | null;
}
