import classNames from "classnames";
import {
  Button,
  Code,
  Divider,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Icon,
} from "@chakra-ui/react";

import styles from "./ScriptEditor.module.scss";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import { useState } from "react";
import { EvaluateScriptResonse, evaluateScript } from "../api/scripts-client";
import { DataType } from "../api/data-tables-client";
import { MdCheck, MdKeyboard, MdTextFields } from "react-icons/md";
import { IconType } from "react-icons";

const Item = ({ entity: { name } }) => <div>{name}</div>;

function testInput(
  dataType: DataType,
  value: any | null,
  onChange: (v: any) => void
) {
  switch (dataType) {
    case DataType.INTEGER:
    case DataType.DECIMAL:
      return (
        <NumberInput
          value={value || 0}
          onChange={(_, e) => {
            onChange(e);
          }}
        >
          <NumberInputField />
        </NumberInput>
      );
    default:
      return (
        <Input
          value={value || ""}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      );
  }
}

function testValue(dataType: DataType): any {
  switch (dataType) {
    case DataType.DATE:
      return "2006-01-02";
    case DataType.DATETIME:
      return "2006-01-02T15:30:00Z";
    case DataType.DECIMAL:
      return 1.0;
    case DataType.INTEGER:
      return 1;
    case DataType.ENUM:
    case DataType.TEXT:
      return "test";
  }
}

async function runTest(
  script: string,
  variables: { [key: string]: DataType },
  values: { [key: string]: any },
  dataType: DataType,
  setResult: (r: EvaluateScriptResonse | null) => void
): Promise<EvaluateScriptResonse | null> {
  setResult(null);

  let scriptSource = script;
  let sanitizedValues = {};
  let sanitizedCols: string[] = [];
  Object.keys(variables).forEach((v: string) => {
    const sanitizedVariable = v.replaceAll(" ", "_");
    scriptSource = scriptSource.replaceAll(`$${v}`, sanitizedVariable);
  });
  Object.keys(values).forEach((key: string) => {
    const sanitizedCol = key.replaceAll(" ", "_");
    sanitizedCols.push(sanitizedCol);
    sanitizedValues[sanitizedCol] = values[key];
  });
  const res = await evaluateScript(
    sanitizedCols,
    sanitizedValues,
    scriptSource,
    dataType
  );
  setResult(res);

  return res;
}

function TestContainer({
  variables,
  values,
  setValues,
  result,
}: {
  variables: { [key: string]: DataType };
  values: { [key: string]: any };
  setValues: (v: { [key: string]: any }) => void;
  result: EvaluateScriptResonse | null;
}) {
  return (
    <Flex className={styles.testContainer}>
      <Heading size="md">Test your formula</Heading>
      <Text>Using the dummy values below, let's test out your formula</Text>

      <Flex>
        <Flex className={styles.testVariables}>
          <Heading size="sm">Input Dummy Values</Heading>
          {Object.keys(variables).map((variable: string) => {
            return (
              <Flex key={variable} className={styles.testVariableContainer}>
                <Text className={styles.testVariableLabel} mb="8px">
                  {variable}
                </Text>
                {testInput(
                  variables[variable],
                  values[variable],
                  (value: any) => {
                    setValues({
                      ...values,
                      [variable]: value,
                    });
                  }
                )}
              </Flex>
            );
          })}
        </Flex>

        <Flex className={styles.testOutputContainer}>
          <Heading size="sm">Output</Heading>

          <Code
            className={styles.testResult}
            colorScheme={result?.errorMessage ? "red" : undefined}
            children={result?.errorMessage || result?.result || "N/A"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
function HelperDescription({
  title,
  description,
  example,
}: {
  title: string;
  description: string;
  example: string;
}) {
  return (
    <Flex className={styles.helperDescriptionContainer}>
      <Flex className={styles.helperDescription}>
        <Heading size="md">{title}</Heading>
        <Text>{description}</Text>

        <Heading size="sm">Example</Heading>
        <Text>{example}</Text>
      </Flex>
    </Flex>
  );
}

type HelperDetail = {
  icon: IconType;
  name: string;
  helperTitle: string;
  helperDescription: string;
  helperExample: string;
};

const helperDetails: HelperDetail[] = [
  {
    icon: MdTextFields,
    name: "Static",
    helperTitle: "Static",
    helperDescription: "A hard static value",
    helperExample: `"Hello World"`,
  },
  {
    icon: MdTextFields,
    name: "Concatenate",
    helperTitle: "Concatenate",
    helperDescription: "Join multiple string values",
    helperExample: `CONCAT($column1, "a string")`,
  },
  {
    icon: MdTextFields,
    name: "If Null",
    helperTitle: "Fill Missing Values",
    helperDescription:
      "Use “IFNULL” to provide a default value when a column has a blank or missing value",
    helperExample: `IFNULL($customer_city, "Unknown")`,
  },
  {
    icon: MdTextFields,
    name: "Replace",
    helperTitle: "Replace",
    helperDescription:
      "Use “REPLACE” to substitute all occurrences of a specified substring with another within a given string. This function is useful for modifying text or standardising data. Matching is not case-sensitive.",
    helperExample: `REPLACE($title, "Hello", "Hi")`,
  },
  {
    icon: MdKeyboard,
    name: "Operations",
    helperTitle: "Column Operations",
    helperDescription:
      "Use basic functions on one or multiple columns. Accepted operations are [+, -, *, /, %]",
    helperExample: "$price * 0.2",
  },
];

function ScriptEditor({
  initialScript,
  isOpen,
  onClose,
  header,
  variables,
  dataType,
  dataSample,
}: {
  initialScript: string | null;
  isOpen: boolean;
  onClose: (script: string | null, isDelete: boolean) => void;
  header: string;
  variables: { [key: string]: DataType };
  dataType: DataType;
  dataSample: any[] | null;
}) {
  const [script, setScript] = useState(initialScript || "");
  const [testValues, setTestValues] = useState<{ [key: string]: any }>(
    Object.keys(variables)
      .map((variable) => ({
        [variable]: dataSample?.[variable] || testValue(variables[variable]),
      }))
      .reduce(
        (res, curr) => ({
          ...res,
          ...curr,
        }),
        {}
      )
  );
  const [testResult, setTestResult] = useState<EvaluateScriptResonse | null>(
    null
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [passedTest, setPassedTest] = useState(false);

  return (
    <Modal size="4xl" isOpen={isOpen} onClose={() => onClose(null, false)}>
      <ModalOverlay />
      <ModalContent className={styles.content}>
        <ModalHeader>
          {header} ({dataType})
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ReactTextareaAutocomplete
            placeholder="CONCAT(col1, col2)"
            loadingComponent={() => <></>}
            className={styles.textArea}
            containerClassName={styles.textAreaContainer}
            onChange={(e) => {
              setScript(e.target.value);
              if (passedTest) {
                setPassedTest(false);
              }
            }}
            value={script}
            minChar={0}
            trigger={{
              $: {
                dataProvider: (token) => {
                  return Object.keys(variables)
                    .filter(
                      (v) =>
                        !token ||
                        v.toUpperCase().indexOf(token.toUpperCase()) !== -1
                    )
                    .map((v) => ({ name: `$${v}` }));
                },
                component: Item,
                output: (item, _) => item.name,
              },
            }}
          />

          <Flex className={styles.helpContainer}>
            <Tabs
              className={styles.tabs}
              variant="solid-rounded"
              colorScheme="gray"
              orientation="vertical"
              index={selectedTabIndex}
              onChange={setSelectedTabIndex}
            >
              <TabList minWidth="160px" mr="16px">
                {helperDetails.map((h) => (
                  <Tab justifyContent="flex-start" w="100%">
                    <Icon as={h.icon} mr="8px" />
                    {h.name}
                  </Tab>
                ))}
                <Divider />
                <Tab justifyContent="flex-start" w="100%">
                  <Icon as={MdCheck} mr="8px" />
                  Test
                </Tab>
              </TabList>

              <Divider orientation="vertical" borderWidth="2px" m="0" />

              <TabPanels>
                {helperDetails.map((h) => (
                  <TabPanel p="0">
                    <HelperDescription
                      title={h.helperTitle}
                      description={h.helperDescription}
                      example={h.helperExample}
                    />
                  </TabPanel>
                ))}
                <TabPanel p="0">
                  <TestContainer
                    variables={variables}
                    values={testValues}
                    setValues={setTestValues}
                    result={testResult}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </ModalBody>

        <ModalFooter>
          {!!initialScript && (
            <Button
              mr="auto"
              w="160px"
              colorScheme="red"
              onClick={async () => {
                onClose(null, true);
              }}
            >
              Delete
            </Button>
          )}
          <Button
            mr="8px"
            w="160px"
            variant="outline"
            colorScheme="primaryScheme"
            onClick={async () => {
              const res = await runTest(
                script,
                variables,
                testValues,
                dataType,
                setTestResult
              );
              setSelectedTabIndex(helperDetails.length);
              if (res?.errorMessage) {
                return;
              }
              setPassedTest(true);
            }}
          >
            Test Formula
          </Button>
          <Button
            w="160px"
            colorScheme="primaryScheme"
            color="white"
            isDisabled={!passedTest}
            onClick={async () => {
              if (!passedTest) {
                return;
              }

              onClose(script.trim(), false);
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ScriptEditor;
