import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Image,
  FormControl,
  Spinner,
} from "@chakra-ui/react";
import { FirebaseError } from "firebase/app";
import { useState } from "react";
import { useQuery } from "react-query";
import { Navigate } from "react-router-dom";
import { useFirebaseAuth } from "../api/firebase";
import { getMe } from "../api/users-client";
import styles from "./Login.module.scss";

function getErrorMessage(errorCode: string) {
  switch (errorCode) {
    case "auth/invalid-email":
      return "Invalid email address";
    case "auth/user-not-found":
      return "Email address not found";
    case "auth/wrong-password":
      return "Invalid email address or password";
  }

  return "Error occurred";
}

function Login({ tenantId }: { tenantId: string }) {
  const { loading, signInWithPassword } = useFirebaseAuth(tenantId);
  const [signInError, setSignInError] = useState<string | null>(null);
  const {
    data: user,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useQuery("me", getMe);

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleShowBtnClick = () => setShow(!show);

  if (isLoadingUser) {
    return (
      <Flex className={styles.spinnerContainer}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  if (user) {
    return <Navigate to="/datasources" />;
  }
  return (
    <Flex className={styles.container}>
      <Flex className={styles.loginBox}>
        <Image
          src="/database.png"
          className={styles.databaseImg}
          alt="referencr"
        />
        <Heading className={styles.loginHeading}>referencr</Heading>
        <FormControl>
          <Box className={styles.inputsContainer}>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={styles.loginInput}
              placeholder="Enter email"
              type="email"
              size="md"
            />
            <InputGroup className={styles.loginInput} size="md">
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={show ? "text" : "password"}
                placeholder="Enter password"
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleShowBtnClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>

          {signInError && (
            <Flex className={styles.signInError}>{signInError}</Flex>
          )}

          <Button
            onClick={async () => {
              setSignInError(null);
              try {
                await signInWithPassword(email, password);
              } catch (e: any) {
                const errorCode = e.code;
                setSignInError(getErrorMessage(errorCode));
                console.error(e);
              }
              await refetchUser();
            }}
            type="submit"
            className={styles.loginBtn}
            isLoading={loading}
            loadingText="Logging in..."
            colorScheme="primaryScheme"
            color="white"
          >
            Login
          </Button>
        </FormControl>
      </Flex>
    </Flex>
  );
}

export default Login;
