import {
  Button,
  Flex,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  DataTableUpload,
  DataTableUploadStatus,
  getUploadById,
} from "../../../api/data-table-uploads-client";
import { DataTable } from "../../../api/data-tables-client";
import ReviewTable from "./ReviewTable";
import styles from "./ReviewAndFinalize.module.scss";
import { useParams } from "react-router-dom";
import { unstable_batchedUpdates } from "react-dom";

export default function ReviewAndFinalize({
  dataTable,
  newUpload,
  onContinue,
  isLoading,
  onCancel,
}: {
  dataTable: DataTable;
  newUpload: DataTableUpload | null;
  onContinue: (updatedEntries: {
    [key: string]: { [key: string]: any };
  }) => void;
  isLoading: boolean;
  onCancel: (u: DataTableUpload | null) => void;
}) {
  const { dataTableUploadId } = useParams();

  const [upload, setUpload] = useState(newUpload);
  const [updatedEntries, setUpdatedEntries] = useState({});
  const [loading, setLoading] = useState(
    newUpload == null && dataTableUploadId != null && dataTableUploadId !== ""
  );

  const totalCount = upload?.rowCount || 0;
  const rowFailedCount = upload?.rowFailureCount || 0;
  const cleanCount = (upload?.rowCount || 0) - (upload?.rowFailureCount || 0);

  useEffect(() => {
    if (upload == null && dataTableUploadId) {
      getUploadById(dataTableUploadId).then((u) => {
        if (u && u.status === DataTableUploadStatus.CANCELLED) {
          onCancel(u)
          return;
        }
        unstable_batchedUpdates(() => {
          setUpload(u);
          setLoading(false);
        });
      });
    }
  });

  if (isLoading || loading) {
    return (
      <Flex className={styles.spinnerContainer}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  return (
    <Flex className={styles.reviewAndFinalizeContainer}>
      <Tabs>
        <TabList>
          {rowFailedCount > 0 && <Tab>Rows with issues {rowFailedCount}</Tab>}
          {/* {cleanCount > 0 && <Tab>Clean rows {cleanCount}</Tab>}
          <Tab>All rows {totalCount}</Tab> */}
          {/* <Tab></Tab> */}
        </TabList>

        <TabPanels>
          {rowFailedCount > 0 && (
            <TabPanel>
              <ReviewTable
                dataTable={dataTable}
                upload={upload!}
                failedOnly={true}
                updatedEntries={updatedEntries}
                setUpdatedEntries={setUpdatedEntries}
              />
            </TabPanel>
          )}
          {/* {cleanCount > 0 && (
            <TabPanel>
              <ReviewTable
                dataTable={dataTable}
                upload={upload}
                failedOnly={false}
                updatedEntries={updatedEntries}
                setUpdatedEntries={setUpdatedEntries}
              />
            </TabPanel>
          )}
          <TabPanel>
            <ReviewTable
              dataTable={dataTable}
              upload={upload}
              failedOnly={null}
              updatedEntries={updatedEntries}
              setUpdatedEntries={setUpdatedEntries}
            />
          </TabPanel> */}
        </TabPanels>
      </Tabs>

      <Flex className={styles.btnContainer}>
        <Button
          onClick={() => onCancel(upload)}
          w={130}
          fontWeight="normal"
          variant="outline"
          size="md"
        >
          Cancel
        </Button>
        <Button
          isLoading={isLoading}
          disabled={false}
          onClick={() => onContinue(updatedEntries)}
          colorScheme="primaryScheme"
          color="white"
        >
          Save & Process
        </Button>
      </Flex>
    </Flex>
  );
}
