import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import { ReactNode, useRef, useState } from "react";

import styles from "./SimpleDialog.module.scss";

export function DeleteSimpleDialog({
  title,
  body,
  className = undefined,
  canDelete,
  isLoading,
  onAttempt = undefined,
  onDelete,
}: {
  title: string;
  body: string;
  className?: string;
  canDelete: boolean;
  isLoading: boolean;
  onAttempt?: () => boolean;
  onDelete: () => void;
}) {
  const modelCancelBtnRef = useRef<HTMLButtonElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {canDelete && (
        <>
          <Button
            className={className}
            colorScheme="red"
            onClick={() => {
              if (onAttempt && !onAttempt()) {
                return;
              }
              setIsModalOpen(true);
            }}
          >
            Delete
          </Button>

          <SimpleDialog
            isOpen={isModalOpen}
            title={title}
            body={body}
            leastDestructiveRef={modelCancelBtnRef}
            onClose={closeModal}
            actions={() => (
              <>
                <Button ref={modelCancelBtnRef} onClick={closeModal}>
                  Cancel
                </Button>
                <Button
                  id="delete-btn"
                  isLoading={isLoading}
                  colorScheme="red"
                  onClick={onDelete}
                  ml={3}
                >
                  Delete
                </Button>
              </>
            )}
          />
        </>
      )}
    </>
  );
}

export function SimpleDialog({
  isOpen,
  title,
  body,
  leastDestructiveRef,
  onClose,
  actions,
}: {
  isOpen: boolean;
  title: string;
  body: string;
  leastDestructiveRef: React.RefObject<HTMLButtonElement>;
  onClose: () => void;
  actions: (() => ReactNode) | null;
}) {
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={leastDestructiveRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{body}</AlertDialogBody>

            {actions && <AlertDialogFooter>{actions()}</AlertDialogFooter>}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
