import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import styles from "./ReviewTable.module.scss";
import { getConnections } from "../../../api/connections-client";
import {
  PaginatedSimpleTable,
  TableColumn,
} from "../../../components/SimpleTable";
import {
  DataTableUpload,
  UploadData,
  getUploadEntries,
  parseUploadEntryValidationErrors,
} from "../../../api/data-table-uploads-client";
import {
  DataTable,
  DataTableUploadEntry,
  dataTypeToTableColumnDataType,
} from "../../../api/data-tables-client";
import { ReactNode } from "react";
import classNames from "classnames";

function tooltipWrapper(
  row: any,
  column: TableColumn,
  value: any,
  renderedColumn: ReactNode
): ReactNode {
  const validationErrs = (row._validationErrs || [])[column.id.toLowerCase()];
  if (validationErrs != null && validationErrs.length > 0) {
    return (
      <>
        <Tooltip hasArrow label={validationErrs.join("\n")} bg="red.600">
          <div className={styles.validationError}>{renderedColumn}</div>
        </Tooltip>
      </>
    );
  }

  return <>{renderedColumn}</>;
}

export default function ReviewTable({
  upload,
  dataTable,
  failedOnly,
  updatedEntries,
  setUpdatedEntries,
}: {
  dataTable: DataTable;
  upload: DataTableUpload;
  failedOnly: boolean | null;
  updatedEntries: { [key: string]: { [key: string]: any } };
  setUpdatedEntries: (entries: {
    [key: string]: { [key: string]: any };
  }) => void;
}) {
  const columns = dataTable.columns.map((col) => ({
    id: col.name,
    name: col.name,
    dataType: dataTypeToTableColumnDataType(col.dataType),
  }));

  return (
    <Flex className={styles.reviewTableContainer}>
      <PaginatedSimpleTable
        className={classNames(styles.table, styles.reviewTable)}
        inputClassName={styles.tableInput}
        editable={true}
        columns={columns}
        onChange={(row: any, idx: number, column: TableColumn, value: any) => {
          const newEntryValue = { ...row };
          newEntryValue[column.id] = `${value}`;
          delete newEntryValue["_dataTableEntryId"];
          delete newEntryValue["_validationErrs"];

          setUpdatedEntries({
            ...updatedEntries,
            [row._dataTableEntryId]: newEntryValue,
          });
        }}
        columnContainerProvider={tooltipWrapper}
        getData={(page, limit) =>
          getUploadEntries(upload.dataTableUploadId!!, failedOnly, page, limit)
        }
        dataName={`upload_entries_${upload.dataTableUploadId}_${failedOnly}`}
        transform={(entries) =>
          (entries?.results || []).map((entry: any) => {
            const entryValue =
              updatedEntries[entry.dataTableEntryId] || entry.value;
            const validationErrs = parseUploadEntryValidationErrors(
              entry.validationErrors
            );
            return {
              _dataTableEntryId: entry.dataTableEntryId,
              _validationErrs: validationErrs,
              ...entryValue,
            };
          })
        }
      />
    </Flex>
  );
}
