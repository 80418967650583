import { Flex, Heading, Spinner } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import styles from "./Uploads.module.scss";
import {
  DataTable,
  DataType,
  getDataTable,
} from "../../api/data-tables-client";
import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { getMe } from "../../api/users-client";
import UploadTabs from "./components/UploadTabs";
import BreadcrumbBar from "../../components/BreadcrumbBar";
import { useUploadService } from "../../services/upload-service";

function Uploads() {
  const uploadService = useUploadService();
  const { dataTableId, dataTableUploadId } = useParams();
  const { data: user, isLoading: isLoadingUser } = useQuery("me", getMe);
  const [name, setName] = useState("");
  const [dataTable, setDataTable] = useState<DataTable | null>(null);

  const [loading, setLoading] = useState(
    dataTableId != null && dataTableId !== ""
  );

  useEffect(
    () => {
      uploadService.setProcessingUpload(null);

      if (dataTableId) {
        getDataTable(dataTableId).then((dataTable) => {
          unstable_batchedUpdates(() => {
            setDataTable(dataTable);
            setName(dataTable?.name || name);
            setLoading(false);
          });
        });
      }
    },
    /* eslint-disable */
    []
  );

  const isLoading = (isLoadingUser || loading) && !dataTableId;

  if (isLoading) {
    return (
      <Flex className={styles.spinnerContainer}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  return (
    <Flex className={styles.container}>
      <BreadcrumbBar
        list={[
          { name: "Data sources", to: "/datasources" },
          { name, to: `/datasources/${dataTableId}` },
          { name: "Upload" },
        ]}
      />

      <Heading as="h1" size="lg" p={0} m={0} mb={8}>
        {name}
      </Heading>

      <Flex className={styles.container} m={0}>
        <UploadTabs
          user={user!}
          dataTableId={dataTableId!}
          dataTable={dataTable!}
        />
      </Flex>
    </Flex>
  );
}

export default Uploads;
