import classNames from "classnames";
import { Box, Flex, Icon } from "@chakra-ui/react";

import styles from "./CreateBoxes.module.scss";
import { FaDatabase, FaPlug } from "react-icons/fa";
import { Link } from "react-router-dom";

function FirstCreateBoxes() {
  return (
    <Flex className={styles.container}>
      <Link to="/connections/new">
        <Box className={styles.box} boxSize="sm" borderWidth="1px">
          <Icon className={styles.icon} as={FaPlug} />
          Create a connection
        </Box>
      </Link>
      <Link to="/datasources/new">
        <Box className={styles.box} boxSize="sm" borderWidth="1px">
          <Icon className={styles.icon} as={FaDatabase} />
          Create a data source
        </Box>
      </Link>
    </Flex>
  );
}

export default FirstCreateBoxes;
